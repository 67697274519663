import logo from "./logo.svg";
import "./App.css";

const { version } = require("../package.json");

function App() {
  return (
    <div className="App">
      <div className="App-content">
        <a href="https://joinmosaic.com">
          <img src={logo} className="App-logo" alt="logo" />
        </a>
        <p>Hello World!</p>
        <p className="version">v {version}</p>
      </div>
    </div>
  );
}

export default App;
